// extracted by mini-css-extract-plugin
export var aiIconContainer = "_AI_Section_Component-module--aiIconContainer--IS-pt";
export var feature = "_AI_Section_Component-module--feature--3d6V3";
export var featureList = "_AI_Section_Component-module--featureList--+zCkm";
export var imageContainer = "_AI_Section_Component-module--imageContainer--0KvoL";
export var item_Text = "_AI_Section_Component-module--item_Text---bQMj";
export var limitWidthOnLargeScreens = "_AI_Section_Component-module--limitWidthOnLargeScreens--769Hc";
export var sectionContent = "_AI_Section_Component-module--sectionContent--YGDla";
export var sectionFooter = "_AI_Section_Component-module--sectionFooter--xnrbu";
export var sectionHeader = "_AI_Section_Component-module--sectionHeader--l1FQK";
export var sectionReverse = "_AI_Section_Component-module--sectionReverse --zaNDd";
export var sectionTitle = "_AI_Section_Component-module--sectionTitle--LgVEo";
export var video = "_AI_Section_Component-module--video--pzllX";
export var wrapper = "_AI_Section_Component-module--wrapper--2kLZ2";