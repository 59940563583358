import React from "react";
import { PageMargins, TypographyV2 } from "@components/index";
import * as styles from "./_AI_Hero.module.scss";
import {
	RequestDemoButtonV2,
	SignUpButtonV2,
} from "@components/Button_new/Button";
import { StaticImage } from "gatsby-plugin-image";
import { CurvePath } from "../_AI_Path/_AI_Path";
import AI_VIDEO from "../../_assets/ai-feature-video.mp4";

export function AIHero() {
	function AnchorTerms() {
		return (
			<a href="#chatGptTerms">
				<sup
					style={{
						color: "var(--brand-subtle)",
					}}
				>
					1
				</sup>
			</a>
		);
	}

	return (
		<PageMargins widthOnLargeScreensClass={styles.widthOnLargeScreensClass}>
			<div className={styles.heroContainer}>
				<div className={styles.heroContent}>
					<div className={styles.upperText}>
						<TypographyV2
							className={styles.heroTitle}
							weight="SEMIBOLD"
						>
							Genemod AI: The future of biopharma ELN
						</TypographyV2>
						<TypographyV2
							color="brand-subtle"
							weight="REGULAR"
							className={styles.heroDescription}
						>
							Our innovative AI-Powered Lab Notebook with advanced
							ChatGPT will transform your research experience.
						</TypographyV2>
					</div>
					<div className={styles.heroButtons}>
						<RequestDemoButtonV2 />
						<SignUpButtonV2 />
					</div>
				</div>
				<div className={styles.heroImage}>
					<video
						className={styles.videoContainer}
						muted
						autoPlay
						loop
						playsInline
						preload="auto"
					>
						<source src={AI_VIDEO} type="video/mp4" />
					</video>
				</div>
			</div>
			<div className={styles.curvePathContainer}>
				<CurvePath />
			</div>
		</PageMargins>
	);
}
